import './header.css';

export default class Header {
  constructor() {
    this.elem = document.querySelector('.js-header');
    this.navToggleButton = this.elem.querySelector('.js-header-nav-toggle');
    this.documentation = document.querySelector('.js-documentation');

    this.handleToggleNavListener = this.handleToggleNav.bind(this);
  }

  bind() {
    this.navToggleButton.addEventListener('click', this.handleToggleNavListener);
  }

  handleToggleNav(event) {
    event.preventDefault();
    this.elem.classList.toggle('is-nav-opened');
    document.body.classList.toggle('no-scroll');
  }

  init() {
    this.bind();
  }
}
