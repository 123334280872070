import { validateEmail, removeSpacesFromInputs } from '../../utils/validate';
import request from '../../utils/request';
import './subscribe.css';
import './result-success.css';
import './inputBlock.css';

export default class Subscribe {
  constructor() {
    this.selectors = {
      form: '.js-form',
      formContent: '.js-form-content',
      inputBlock: '.js-input-block',
      inputElement: '.js-form-input',
      successContainer: '.js-result-success',
      errorContainer: '.js-result-error',
      result: '.js-form-result',
      submitTextLink: '.js-subscribe-form-link',
    };

    this.els = {
      forms: document.querySelectorAll(this.selectors.form),
    };

    this.handleSubmitListener = this.handleSubmit.bind(this);
  }

  bind() {
    const { forms } = this.els;
    [].forEach.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        this.handleFormElements(form);
        this.handleSubmitListener(event, form.id);
      });

      const textLink = form.querySelector(this.selectors.submitTextLink);
      if (textLink) {
        textLink.addEventListener('click', (event) => {
          this.handleSubmitListener(event, form.id);
        });
      }
    });
  }

  handleFormElements(form) {
    this.els[form.id] = {
      form,
      formContent: form.querySelector(this.selectors.formContent),
      inputBlocks: form.querySelectorAll(this.selectors.inputBlock),
      inputElements: form.querySelectorAll(this.selectors.inputElement),
      successContainer: form.querySelector(this.selectors.successContainer),
      errorContainer: form.querySelector(this.selectors.errorContainer),
      result: form.querySelector(this.selectors.result),
    };
  }

  handleSubmit(event, formId) {
    event.preventDefault();

    const { inputElements } = this.els[formId];

    this.clearValidationData(formId);

    removeSpacesFromInputs(inputElements);

    let hasErrors = false;
    const sendData = {};

    [].forEach.call(inputElements, (inputElement) => {
      const { name, value } = inputElement;
      const isRequired = !inputElement.classList.contains('js-not-required');

      if (name === 'email' ? !validateEmail(value) : !value && isRequired) {
        hasErrors = true;
        this.showValidateError(inputElement);
      }

      sendData[name] = value;
    });

    if (!hasErrors) {
      this.sendRequest(sendData, formId);
    }
  }

  sendRequest(data, formId) {
    const { form } = this.els[formId];
    const url = `${form.action}`;
    const dataJson = JSON.stringify(data);

    request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: dataJson,
    }).then(() => {
      this.handleRequestSuccess(formId);
    }).catch((errorData) => {
      this.handleRequestError(errorData, formId);
    });
  }

  handleRequestSuccess(formId) {
    const { formContent, result, successContainer } = this.els[formId];

    this.hideResponseBlock(formId);

    formContent.classList.add('hide');
    result.classList.remove('hide');
    successContainer.classList.remove('hide');
  }

  handleRequestError(errorData, formId) {
    const { inputElements } = this.els[formId];

    this.hideResponseBlock(formId);

    if (errorData.error) {
      this.showResponseError(errorData.error, formId);
      return;
    }

    [].forEach.call(inputElements, (inputElement) => {
      const { name } = inputElement;
      if (errorData.errors && errorData.errors[name] !== undefined) {
        this.showValidateError(inputElement);
      }
    });
  }

  showValidateError(inputElement) {
    const inputBlock = inputElement.closest(this.selectors.inputBlock);
    inputBlock.classList.add('has-error');
  }

  showResponseError(errorTxt, formId) {
    const { result, errorContainer } = this.els[formId];

    result.classList.remove('hide');
    errorContainer.classList.remove('hide');
    errorContainer.innerText = errorTxt;
  }

  hideResponseBlock(formId) {
    const { result, errorContainer, successContainer } = this.els[formId];
    [result, errorContainer, successContainer].forEach((item) => {
      if (item) item.classList.add('hide');
    });
  }

  clearValidationData(formId) {
    const { inputBlocks } = this.els[formId];
    [].forEach.call(inputBlocks, (inputBlock) => {
      inputBlock.classList.remove('has-error');
    });
  }

  init() {
    this.bind();
  }
}
