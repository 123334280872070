/* eslint-disable import/prefer-default-export */
function validateEmail(val) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
  return re.test(String(val).toLowerCase());
}

function removeSpacesFromInputs(inputs) {
  inputs.forEach((input) => {
    const { value } = input;
    // eslint-disable-next-line no-param-reassign
    input.value = value.trim();
  });
}

export {
  validateEmail,
  removeSpacesFromInputs,
};
