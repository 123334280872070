import { debounce } from '../../utils/debounce';

import './header-search.css';

const preventDefault = (event) => {
  event.preventDefault();

  return false;
};

export default class HeaderSearch {
  constructor() {
    this.elem = document.querySelector('.js-header-search');
    this.formElem = this.elem.querySelector('.js-header-search-form');
    this.queryElem = this.elem.querySelector('.js-header-search-query');
    this.resultsElem = this.elem.querySelector('.js-header-search-results');

    this.resultsIndex = -1;
    this.resultsItems = [];

    this.isInnerClick = false;

    this.handleSubmitFormListener = preventDefault;
    this.handleQueryFocusListener = this.handleQueryFocus.bind(this);
    this.handleQueryInputListener = this.handleQueryInput.bind(this);
    this.handleKeyDownListener = this.handleKeyDown.bind(this);
    this.handleInsideClickListener = this.handleInsideClick.bind(this);
    this.handleOutsideClickListener = this.handleOutsideClick.bind(this);
  }

  bind() {
    this.formElem.addEventListener('submit', this.handleSubmitFormListener);
    this.queryElem.addEventListener('focus', debounce(this.handleQueryFocusListener, 50));
    this.queryElem.addEventListener('input', debounce(this.handleQueryInputListener, 50));
    this.elem.addEventListener('keydown', this.handleKeyDownListener);
    this.elem.addEventListener('click', this.handleInsideClickListener);
    document.addEventListener('click', this.handleOutsideClickListener);
  }

  handleRequestSuccess(data) {
    if (data.status !== 200) {
      console.error('Fetch error');
      return;
    }

    this.resultsItems = [];

    if (data.hits && data.hits.length) {
      this.resultsItems = data.hits.slice(0, 5).map((hit) => {
        const item = document.createElement('li');
        item.innerHTML = `<a href="/docs/${hit.id}"><span>${hit.title}</span><small>${hit.fragment}</small></a>`;
        return item;
      });
    }

    this.resetResults();
  }

  handleQueryFocus() {
    const hasResults = this.resultsItems.length;

    if (hasResults) {
      this.showResults();
    }
  }

  handleQueryInput() {
    if (!this.queryElem.value) {
      this.clearResults();
      return;
    }

    const data = {};
    data[this.queryElem.name] = this.queryElem.value;
    this.sendRequest(data);
  }

  handleKeyDown(e) {
    const event = e || window.event;

    if (event.keyCode === 38) {
      event.preventDefault();
      this.selectPreviousItem();
    } else if (e.keyCode === 40) {
      event.preventDefault();
      this.selectNextItem();
    }
  }

  handleInsideClick() {
    this.isInnerClick = true;
  }

  handleOutsideClick() {
    if (!this.isInnerClick) {
      this.hideResults();
    }
    this.isInnerClick = false;
  }

  sendRequest(data) {
    const query = Object.keys(data)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
      .join('&');
    fetch(`${this.formElem.action}?${query}`)
      .then((response) => response.json())
      .then((result) => { this.handleRequestSuccess(result); });
  }

  focusItem() {
    this.resultsItems[this.resultsIndex].firstChild.focus();
  }

  selectPreviousItem() {
    this.resultsIndex -= 1;
    if (this.resultsIndex === -2) { // input was selected
      this.resultsIndex = this.resultsItems.length - 1; // select the last
    }
    if (this.resultsIndex === -1) {
      this.queryElem.focus();
      return;
    }
    this.focusItem();
  }

  selectNextItem() {
    this.resultsIndex += 1;
    if (this.resultsIndex === this.resultsItems.length) { // last was selected
      this.resultsIndex = -1;
      this.queryElem.focus();
      return;
    }
    this.focusItem();
  }

  clearResults() {
    this.resultsIndex = -1;
    this.resultsItems = [];
    this.resetResults();
  }

  resetResults() {
    this.resultsElem.innerHTML = '';
    const hasResults = this.resultsItems.length;

    if (hasResults) {
      this.resultsItems.forEach((item) => {
        this.resultsElem.appendChild(item);
      });
      this.showResults();
      return;
    }

    this.hideResults();
  }

  showResults() {
    this.resultsElem.classList.add('is-opened');
  }

  hideResults() {
    this.resultsElem.classList.remove('is-opened');
  }

  init() {
    this.bind();
  }
}
