import './button.css';

function addRippleEffect(e, btn) {
  const circle = document.createElement('div');
  const maxSide = Math.max(btn.clientWidth, btn.clientHeight);
  const rect = btn.getBoundingClientRect();
  const circleStyle = circle.style;

  circleStyle.width = `${maxSide}px`;
  circleStyle.height = `${maxSide}px`;
  circleStyle.left = `${e.clientX - rect.left - (maxSide / 2)}px`;
  circleStyle.top = `${e.clientY - rect.top - (maxSide / 2)}px`;

  circle.classList.add('pulse');
  btn.appendChild(circle);
}

export default class Button {
  constructor() {
    this.elems = document.querySelectorAll('.js-ripple');
  }

  bind() {
    Array.prototype.forEach.call(this.elems, (btn) => {
      btn.addEventListener('click', (e) => addRippleEffect(e, btn));
    });
  }

  init() {
    this.bind();
  }
}
