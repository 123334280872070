/* eslint-disable import/prefer-default-export */
export const debounce = (func, delay = 10) => {
  let timeout = null;
  // eslint-disable-next-line func-names
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
};
